<template>
  <div>
    <v-dialog
      v-model="input"
      width="800"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-titlez
          v-html="content.titlez"
        />
        <v-alert
          :value="true"
          :color="content.color"
          v-html="content.body"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="dialog3 = !dialog3"
          >
            TIDAK
          </v-btn>
          <v-btn
            flat
            @click="dialog4 = !dialog4"
          >
            YA
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :timeout="10000"
        :color="snackbarColor"
        top
      >
        {{ snackbarText }}
        <v-btn
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </v-dialog>
    <v-dialog
      v-model="dialog3"
      persistent
      max-width="500"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-text>Anda telah memilih TIDAK untuk kekal sebagai Basic Dealer.<br> Sila buat pengesahan dengan memilih TERIMA atau BATAL untuk kembali ke paparan sebelum.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="closez"
          >
            BATAL
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="agreePdpa(true)"
          >
            TERIMA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog4"
      persistent
      max-width="500"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-text>Anda telah memilih YA untuk naiktaraf sebagai Full Dealer. <br>Sila buat pengesahan dengan memilih TERIMA atau BATAL untuk kembali ke paparan sebelum.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="closez"
          >
            BATAL
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="agreePdpa(false)"
          >
            TERIMA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createPostParams } from '@/rest'

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    content: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      input: null,
      agree: null,
      snackbar: false,
      snackbarText: null,
      contentz: null,
      snackbarColor: 'success',
      dialog: false,
      dialog3: false,
      dialog4: false,
    }
  },
  mounted: function () {
    this.input = this.value
  },
  methods: {
    close: function () {
      this.input = false
      this.$emit('input', false)
    },

    closez: function () {
      this.dialog3 = false
      this.dialog4 = false
    },

    async agreePdpa (val) {
      if (val) {
        this.agree = 'agree'
      } else {
        this.agree = 'disagree'
      }
      const params = createPostParams({
        agree: this.agree,
      })
      await this.$rest.post('postDowngradeBasicDealer.php', params)
      this.input = false
      this.dialog3 = false
      this.dialog4 = false
      this.$emit('input', false)
    },
  },
}
</script>
